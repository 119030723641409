(function ($) {
  $.extend($.summernote.lang, {
    'hu-HU': {
      font: {
        bold: 'Félkövér',
        italic: 'Dőlt',
        underline: 'Aláhúzott',
        clear: 'Formázás törlése',
        height: 'Sorköz',
        name: 'Betűtípus',
        strikethrough: 'Áthúzott',
        size: 'Betűméret'
      },
      image: {
        image: 'Kép',
        insert: 'Kép beszúrása',
        resizeFull: 'Átméretezés teljes méretre',
        resizeHalf: 'Átméretezés felére',
        resizeQuarter: 'Átméretezés negyedére',
        floatLeft: 'Igazítás balra',
        floatRight: 'Igazítás jobbra',
        floatNone: 'Igazítás törlése',
        dragImageHere: 'Ide húzhatod a képet',
        selectFromFiles: 'Fájlok kiválasztása',
        url: 'Kép URL címe',
        remove: 'Kép törlése'
      },
      link: {
        link: 'Hivatkozás',
        insert: 'Hivatkozás beszúrása',
        unlink: 'Hivatkozás megszüntetése',
        edit: 'Szerkesztés',
        textToDisplay: 'Megjelenítendő szöveg',
        url: 'Milyen URL címre hivatkozzon?',
        openInNewWindow: 'Megnyitás új ablakban'
      },
      table: {
        table: 'Táblázat'
      },
      hr: {
        insert: 'Elválasztó vonal beszúrása'
      },
      style: {
        style: 'Stílus',
        normal: 'Normál',
        blockquote: 'Idézet',
        pre: 'Kód',
        h1: 'Fejléc 1',
        h2: 'Fejléc 2',
        h3: 'Fejléc 3',
        h4: 'Fejléc 4',
        h5: 'Fejléc 5',
        h6: 'Fejléc 6'
      },
      lists: {
        unordered: 'Listajeles lista',
        ordered: 'Számozott lista'
      },
      options: {
        help: 'Súgó',
        fullscreen: 'Teljes képernyő',
        codeview: 'Kód nézet'
      },
      paragraph: {
        paragraph: 'Bekezdés',
        outdent: 'Behúzás csökkentése',
        indent: 'Behúzás növelése',
        left: 'Igazítás balra',
        center: 'Igazítás középre',
        right: 'Igazítás jobbra',
        justify: 'Sorkizárt'
      },
      color: {
        recent: 'Jelenlegi szín',
        more: 'További színek',
        background: 'Háttérszín',
        foreground: 'Betűszín',
        transparent: 'Átlátszó',
        setTransparent: 'Átlászóság beállítása',
        reset: 'Visszaállítás',
        resetToDefault: 'Alaphelyzetbe állítás'
      },
      shortcut: {
        shortcuts: 'Gyorsbillentyű',
        close: 'Bezárás',
        textFormatting: 'Szöveg formázása',
        action: 'Művelet',
        paragraphFormatting: 'Bekezdés formázása',
        documentStyle: 'Dokumentumstílus'
      },
      history: {
        undo: 'Visszavonás',
        redo: 'Újra'
      }

    }
  });
})(jQuery);
